jQuery(document).ready(function ($) {

    AOS.init({
        'once': true
    }); 

    // Logo and Hamburger animation on load
    TweenMax.to($('#site-logo'), 0.5, {delay: 0.5, y: '0%', opacity: 1, ease:Power4.easeInOut});
    TweenMax.to($('.col_hamburger_wrapper > .inner'), 0.5, {delay: 0.5, y: '0%', opacity: 1, ease:Power4.easeInOut});
    TweenMax.to($('#desktop_menu'), 0.5, {delay: 0.5, y: '0%', opacity: 1, ease:Power4.easeInOut});

    jQuery('.col-twins').matchHeight({
        byRow: true,
        property: 'height',
        target: null,
        remove: false
    });

    jQuery('.col-menu').matchHeight({
        byRow: false,
        property: 'height',
        target: null,
        remove: false
    });

    jQuery('.col-menu-sticky').matchHeight({
        byRow: false,
        property: 'height',
        target: null,
        remove: false
    });

    // MOBILE MENU TOGGLE
    function animateMenu(element, action) {
        var $this = element;
        
        if(action == '') {
            jQuery("#side_menu_wrapper").toggleClass('open');
            if(!$this.hasClass("closed")) {

                jQuery(jQuery("#mobile-menu li").get().reverse()).each(function(i) { 
                    TweenLite.to(jQuery(this), 0.2 * i, { ease: Power4.easeInOut, opacity:0});
                });

                TweenLite.to(jQuery("#side_menu_wrapper"), 0.5, {ease: Power4.easeInOut, x: '0%'});
                
                jQuery('#hamburger-icon-close').addClass("closed");
                jQuery("#hamburger-icon").addClass("closed");
                jQuery("#hamburger-icon-sticky").addClass("closed");
                jQuery("body").removeClass("menu-open");
            } else {

                jQuery("#mobile-menu li").each(function(i) {
                    TweenLite.to(jQuery(this), 0.2 * i, {ease: Power4.easeInOut, opacity:1});
                });

                TweenLite.to(jQuery("#side_menu_wrapper"), 0.5, {ease: Power4.easeInOut, x: '-100%'});
                
                jQuery("#hamburger-icon-close").removeClass("closed");
                jQuery("#hamburger-icon").removeClass("closed");
                jQuery("#hamburger-icon-sticky").removeClass("closed");
                jQuery("body").addClass("menu-open");
            }
        } else if(action == 'close') {
            jQuery("#side_menu_wrapper").removeClass('open');
            jQuery(jQuery("#mobile-menu li").get().reverse()).each(function(i) { 
                TweenLite.to(jQuery(this), 0.2 * i, { ease: Power4.easeInOut, opacity:0});
            });

            TweenLite.to(jQuery("#side_menu_wrapper"), 0.5, {ease: Power4.easeInOut, x: '0%'});
            
            jQuery('#hamburger-icon-close').addClass("closed");
            jQuery("#hamburger-icon").addClass("closed");
            jQuery("#hamburger-icon-sticky").addClass("closed");
            jQuery("body").removeClass("menu-open");
        }
    }

    // DESKTOP MENU TOGGLE
    function animateMenuDesktop(element, action) {
        var $this = element;
        if(action == '') {
            jQuery("#side_menu_wrapper_desktop").toggleClass('open');
            if(!$this.hasClass("closed")) {
                TweenLite.to(jQuery("#side_menu_wrapper_desktop"), 0.5, {ease: Power4.easeInOut, x: '0%'});
                
                jQuery('#hamburger-icon-close-desktop').addClass("closed");
                jQuery("#hamburger-icon-desktop").addClass("closed");
                jQuery("#hamburger-icon-sticky-desktop").addClass("closed");
                jQuery("body").removeClass("menu-open");
            } else {
                TweenLite.to(jQuery("#side_menu_wrapper_desktop"), 0.5, {ease: Power4.easeInOut, x: '-100%'});
                
                jQuery("#hamburger-icon-close").removeClass("closed");
                jQuery("#hamburger-icon").removeClass("closed");
                jQuery("#hamburger-icon-sticky").removeClass("closed");
                jQuery("body").addClass("menu-open");
            }
        } else if(action == 'close') {
            jQuery("#side_menu_wrapper_desktop").removeClass('open');

            TweenLite.to(jQuery("#side_menu_wrapper_desktop"), 0.5, {ease: Power4.easeInOut, x: '0%'});
            
            jQuery('#hamburger-icon-close-desktop').addClass("closed");
            jQuery("#hamburger-icon-desktop").addClass("closed");
            jQuery("#hamburger-icon-sticky-desktop").addClass("closed");
            jQuery("body").removeClass("menu-open");
        }
    }

    jQuery('#hamburger-icon-close').click(function() {
        animateMenu(jQuery(this), '');
    });

    jQuery("#hamburger-icon").click(function() {
        animateMenu(jQuery(this), '');
    });

    jQuery("#hamburger-icon-sticky").click(function() {
        animateMenu(jQuery(this), '');
    });

    jQuery('#hamburger-icon-desktop-close').click(function() {
        animateMenuDesktop(jQuery(this), '');
    });

    jQuery("#hamburger-icon-desktop").click(function() {
        console.log('here');
        animateMenuDesktop(jQuery(this), '');
    });

    jQuery("#hamburger-icon-desktop-sticky").click(function() {
        animateMenuDesktop(jQuery(this), '');
    });

    // mobile menu scrolling
    jQuery('#scrollable-wrapper').slimScroll({
        height: '100vh',
        alwaysVisible: false
    });

    // mobile menu scrolling
    jQuery('#scrollable-wrapper-desktop').slimScroll({
        height: '100vh',
        alwaysVisible: false
    });

    jQuery(window).scroll(function() {
        if (jQuery(this).scrollTop() > 600) {
            jQuery('#sticky-header').addClass('visible');
        } else {
            jQuery('#sticky-header').removeClass('visible');
        }
    });

    jQuery('#mobile-menu .menu-item-has-children > a').siblings('.sub-menu').each(function() {
        var tHeight = 0;
        if(!jQuery(this).attr('initH')) {
            console.log();
            jQuery(this).children('li').children('a').each(function() {
                tHeight += $(this).outerHeight(true);
            });
            // console.log(tHeight);
            jQuery(this).attr('initH', tHeight);
            jQuery(this).height(0);
        }
    });


    jQuery('#mobile-menu .menu-item-has-children > a').click(function(e) {
        var toggle = $(this).siblings('.sub-menu');
        e.preventDefault();
        if(toggle.hasClass('active')) {
            toggle.removeClass('active');
            toggle.parents('.sub-menu').each(function() {
                TweenMax.to(jQuery(this), 0.2, {ease: Power4.easeInOut, height: jQuery(this).attr('initH') });
            });
            TweenMax.to(toggle, 0.2, {ease: Power4.easeInOut, height:"0"});
        } else {
            toggle.addClass('active');
            
            toggle.parents('.sub-menu').each(function() {
                TweenMax.to(jQuery(this), 0.2, {ease: Power4.easeInOut, height: parseInt(jQuery(this).attr('initH')) + parseInt(toggle.attr('initH')) });
            });

            TweenMax.to(toggle, 0.2, {ease: Power4.easeInOut, height: toggle.attr('initH')});
        }
    });
});